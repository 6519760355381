<template>
    <div class="seller-page">
        <div class="page-route-select">
            <router-link to="/cliente/monitores/cotacoes" class="page-route-select-text">
                Home
            </router-link>
            <span class="material-icons page-route-select-icon">play_arrow</span>
            <router-link to="/fornecedores" class="page-route-select-text">
                Fornecedores
            </router-link>
            <span class="material-icons page-route-select-icon">play_arrow</span>
            <div class="page-route-select-text">
                Vendedores
            </div>
        </div>
        <div class="page-title">
            Vendedores
        </div>
        <div class="seller-inputs-container2">
            <StandardButton class="seller-input-size-1" text="filtrar" :action="filter" iconName="search" />
            <StandardButton class="seller-input-size-1" text="Limpar filtros" :action="() => {filters={}, filter()}" iconName="delete" />
            <StandardButton class="seller-input-size-1" text="Voltar" :action="goBack" iconName="arrow_back_ios" />
        </div>
        <div class="seller-inputs-container">
            <StandardInput class="seller-input-size" :auto="true" :action="setName" title="Buscar por nome" type="text" />
            <StandardInput class="seller-input-size" :auto="true" :action="setState" title="Selecione um estado" :list="states" type="select" />
            <StandardInputListFilteredAuto class="seller-input-size" :value="filters.client" :auto="true" :action="setClient" title="Cliente" :list="clients" type="select" />
            <StandardInput class="seller-input-size" :auto="true" :action="setProvider" title="Fornecedor" type="text" />
        </div>
        <div class="page-table-header">
          <div class="page-table-header-text id-width">ID</div>
          <div class="page-table-header-text name-width">Nome</div>
          <div class="page-table-header-text email-width">Email</div>
          <div class="page-table-header-text login-width">Login</div>
          <div class="page-table-header-text password-width">Senha</div>
          <div class="page-table-header-text phone-width">Telefone</div>
          <div class="page-table-header-text provider-width">Fornecedor</div>
          <div class="page-table-header-text state-width">Estado</div>
          <div class="page-table-header-text city-width">Cidade</div>
          <div class="page-table-header-text actions-width">Açoes</div>
        </div>
        <div class="seller-row" v-for="(seller, idx) in sellers" v-bind:key="seller.id" :class="getSpecialBackground(idx)">
            <div class="seller-row-text id-width">
                <div class="page-table-header-mobile">ID</div>
                {{ '# ' + seller.id }}
            </div>
            <div class="seller-row-text name-width">
                <div class="page-table-header-mobile">Nome</div>
                {{ seller.name }}
            </div>
            <div class="seller-row-text email-width">
                <div class="page-table-header-mobile">Email</div>
                {{ seller.email }}
            </div>
            <div class="seller-row-text login-width">
                <div class="page-table-header-mobile">Login</div>
                {{ seller.login }}
            </div>
            <div class="seller-row-text password-width">
                <div class="page-table-header-mobile">Senha</div>
                <span @click=" showPasswordModal(seller) " class="material-icons-outlined password-icon"
                    title="Ver Senha">
                    lock
                </span>
            </div>
            <div class="seller-row-text phone-width" v-if="seller.phone">
                <div class="page-table-header-mobile">Telefone</div>
                <cc-whatsapp :phone="seller.phone" />
            </div>
            <div class="seller-row-text phone-width" v-else>
                <div class="page-table-header-mobile">Telefone</div>
                --
            </div>
            <div class="seller-row-text provider-width">
                <div class="page-table-header-mobile">Fornecedor</div>
                {{ '# ' + seller.provider.name }}
            </div>
            <div class="seller-row-text state-width">
                <div class="page-table-header-mobile">Estado</div>
                {{ seller.provider.head_office && seller.provider.head_office.state ? seller.provider.head_office.state.name : '-' }}
            </div>
            <div class="seller-row-text city-width">
                <div class="page-table-header-mobile">Cidade</div>
                {{ seller.provider.head_office && seller.provider.head_office.city ? seller.provider.head_office.city.name : '-' }}
            </div>
            <div class="seller-row-text actions-width">
                <div class="page-table-header-mobile">Açoes</div>
                <div>
                    <span class="material-icons-outlined seller-row-icon" style="color: #20CE88;" title="Clientes" @click="show_users(seller, true)">people</span>
                    <span class="material-icons-outlined seller-row-icon" style="color: #4267B2;" @click="show_participations(seller)" title="Histórico de Cotações">list_alt</span>
                    <span class="material-icons-outlined seller-row-icon" style="color: red;" title="Remover vendedor" @click="remove_seller(seller)">delete</span>
                    <span class="material-icons-outlined seller-row-icon" style="color: #5e72e4;" title="Editar vendedor" @click="show_users(seller)">edit</span>
                </div>
                
            </div>
        </div>
        <div class="box-footer clearfix not-print">
            <cc-paginacao
                :key="'pag'"
                classes="orange"
                :items_by_page_value="items_by_page_value"
                :total="total_items"
                :page="paginaAtual"
                :page_limit="11"
                v-on:change="load($event)"></cc-paginacao>
        </div>
        
    <cc-loader-full v-if="is_loading" />
    <SellerParticipationModal
        :seller="current_seller"
        @close="is_seller_participation_modal_open=false"
        v-if="is_seller_participation_modal_open" />
    <SellersModal
        @close="is_provider_users_modal_open=false"
        v-if="is_provider_users_modal_open"
        :seller_id="current_seller.id"
        :to_clients="current_seller.to_clients"
        @reload="load"
        :provider="current_provider" />
    <SellersLoginModal v-if=" isPasswordModalOpen " @close=" isPasswordModalOpen = false " :sellerInfo=" sellerInfo " />
    </div>

    <!-- <div class="monitors">
      <div class="body">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <form @submit.prevent="filter">
                            <div class="row py-3">
                                <div class="col">
                                    <label for="" class="label-control">Buscar por nome</label>
                                    <cc-search :noicon="true" v-model="filters.name" />
                                </div>
                                <div class="col col-md-2 col-lg">
                                    <label for="" class="label-control">Selecione um estado</label>
                                    <cc-select-v2
                                        :tracker="'est_id'"
                                        :text="'est_nome'"
                                        :searchable="true"
                                        :options="states"
                                        v-model="filters.state" />
                                </div>
                                <div class="col">
                                    <label for="" class="label-control">Cliente</label>
                                    <cc-select-v2
                                        tracker="id"
                                        :text="'name'"
                                        :searchable="true"
                                        :placeholder="'Selecione um cliente'"
                                        :options="clients"
                                        v-model="filters.client" />
                                </div>
                                <div class="col">
                                    <label for="" class="label-control">Fornecedor</label>
                                    <cc-search :noicon="true" v-model="filters.provider" />
                                </div>
                                <div class="col col-1">
                                    <label for="" class="label-control">&nbsp;</label>
                                    <cc-button
                                        :nolink="true"
                                        type="submit"
                                        :classes="'fixed success'"
                                        :content="'Filtrar'"
                                        @click="filter" />
                                </div>
                                <div class="col col-2">
                                    <label for="" class="label-control">&nbsp;</label>
                                    <cc-button
                                        :classes="'fixed danger-outline'"
                                        :icon="'far fa-trash-alt'"
                                        :content="'Limpar Filtros'"
                                        @click="filters={}" />
                                </div>
                                <div class="col-1">
                                    <label for="" class="label-control">&nbsp;</label>
                                    <cc-button
                                        :classes="'fixed theme-1 active'"
                                        :icon="'fas fa-chevron-left'"
                                        :content="'Voltar'"
                                        :link="'/fornecedores'" />
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="col-12 col-12 p-4 shadow rounded">
                        <cc-loader v-show="is_loading" class="default" />
                        <table class="table" :class="{ hidden : is_loading }">
                            <thead>
                                <tr>
                                    <th class="pl-3">Id</th>
                                    <th class="pl-3">Nome</th>
                                    <th>Email</th>
                                    <th>Login</th>
                                    <th>Senha</th>
                                    <th>Telefone</th>
                                    <th>Fornecedor</th>
                                    <th>Estado</th>
                                    <th>Cidade</th>
                                    <th class="text-center">Ações</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(seller) in sellers" v-bind:key="seller.id">
                                    <td class="id"><strong>#{{ seller.id }}</strong></td>
                                    <td class="pl-3">{{ seller.name }}</td>
                                    <td>{{ seller.email }}</td>
                                    <td class="text-uppercase">{{ seller.login }}</td>
                                    <td>{{ seller.password }}</td>
                                    <td><cc-whatsapp :phone="seller.phone" /></td>
                                    <td class="id"><strong>#{{ seller.provider.name }}</strong></td>
                                    <td>{{ seller.provider.head_office && seller.provider.head_office.state ? seller.provider.head_office.state.name : '-' }}</td>
                                    <td>{{ seller.provider.head_office && seller.provider.head_office.city ? seller.provider.head_office.city.name : '-' }}</td>
                                    <td class="text-center">
                                        <div class="actions d-flex">
                                            <i class="fas fa-user-friends"
                                                title="Clientes"
                                                @click="show_users(seller, true)"></i>
                                            <i @click="show_participations(seller)"
                                                title="Histórico de Cotações"
                                                class="fas fa-clipboard-list"></i>
                                            <i title="Remover vendedor"
                                                class="fa fa-trash"
                                                @click="remove_seller(seller)"></i>
                                            <i class="fa fa-pencil"
                                                @click="show_users(seller)" />
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="box-footer clearfix not-print">
                    <cc-paginacao
                        :key="'pag'"
                        classes="orange"
                        :items_by_page_value="items_by_page_value"
                        :total="total_items"
                        :page="paginaAtual"
                        :page_limit="11"
                        v-on:change="load($event)"></cc-paginacao>
                </div>
            </div>
            <SellerParticipationModal
                :seller="current_seller"
                @close="is_seller_participation_modal_open=false"
                v-if="is_seller_participation_modal_open" />
            <SellersModal
                @close="is_provider_users_modal_open=false"
                v-if="is_provider_users_modal_open"
                :seller_id="current_seller.id"
                :to_clients="current_seller.to_clients"
                @reload="load"
                :provider="current_provider" />
        </div>
    </div> -->
</template>
<script>

import Paginacao from "@/components/cliente/base-components/Pagination";
import SellerService from "@/services/v3/providers/seller.service";
import FilterService from "@/services/v3/filters/filter.service";
import SellersModal from "@/modals/providers/sellers/sellers.modal"
import ErrorHandlerService from "@/services/ErrorHandlerService";
import { loaderMixin } from '@/mixins/sweet-loader.mixin'
import ProviderModal from "@/modals/provider/new/new-provider.modal";
import SellerParticipationModal from "@/modals/providers/sellers/sellers-participation.modal";
import UserService from "@/services/v1/user.service"
import StandardButton from '@/components/ui/buttons/StandardButton.vue';
import StandardInput from '@/components/ui/inputs/StandardInputV2.vue'
import ClientServiceV2 from "@/services/v2/client.service";
import StandardInputListFilteredAuto from "@/components/ui/inputs/StandardInputListFilteredAuto.vue";
import SellersLoginModal from '@/modals/providers/sellers/sellers-password-modal.vue';

export default {
    mixins: [ loaderMixin ],
    components: {
        ProviderModal,
        SellerParticipationModal,
        SellersModal,
        ccPaginacao: Paginacao,
        StandardButton,
        StandardInput,
        StandardInputListFilteredAuto,
        SellersLoginModal
    },
    data(){
        return{
          image:"/images/search.png",
            is_loading: false,
            paginaAtual: 1,
            total_items: 1,
            items_by_page_value: 100,
            svc: new SellerService(),
            user_svc: new UserService(),
            v2_cli_svc: new ClientServiceV2(),
            filter_svc: new FilterService(),
            sellers: [],
            states: [],
            providers: [],
            clients: [],
            isPasswordModalOpen: false,
            sellerInfo: undefined,
            is_provider_modal_open: false,
            is_provider_users_modal_open: false,
            is_seller_participation_modal_open: false,
            filters: {
                page: 1
            },
            current_provider: null,
            breadcrumbs: [
                {
                    name: "Fornecedores",
                    route: 'sellers'
                },
                {
                    name: "Vendedores",
                    route: 'providers-sellers'
                }
            ]
        }
    },
    methods: {
        getSpecialBackground (idx) { if (idx % 2 != 0) return 'page-table-line-special' },
        setName (value) { this.filters.name = value},
        setProvider (value) { this.filters.provider = value},
        setState (value) { this.filters.state = value},
        setClient (value) { this.filters.client = value; this.load()},
        goBack () { this.$router.push('/fornecedores') },
        filter() {
            this.paginaAtual = 1
            this.load()
        },
        show_participations(seller) {
            this.current_seller = seller
            this.is_seller_participation_modal_open = true
        },
        close_modal(reload = false) {
            this.is_provider_modal_open = false
            this.current_provider = null
            if(reload) {
                this.load()
            }
        },
        load_client_stores() {
			return this.v2_cli_svc.list_client_stores().then(response => response.data).then(data => {
				this.clients = data.data.map(cli => { return { value: cli.cli_id, text: cli.cli_nome_grupo } })
			})
		},
        show_users(seller, to_list_clients = false) {
            this.current_provider = seller.provider
            this.current_seller = { ...seller, to_clients: to_list_clients }
            this.is_provider_users_modal_open = true
        },
        load_region_data() {
			return this.user_svc.region_data().then(response => response.data).then(data => {
				this.states = data.estados.map(state => {return {value: state, text:state.est_nome}})
			})
		},
        remove_seller(seller) {
            this.confirm_action({
                message: `Confirma remoção do vendedor ${seller.name}?` ,
                callback: () => this.remove(seller.id)
            })
        },
        remove(seller_id) {
            return this.svc.remove(seller_id).then(() => this.load())
        },
        showPasswordModal(seller) {
            this.sellerInfo = seller;
            this.isPasswordModalOpen = true;
        },
        load(new_page = this.paginaAtual) {
            this.paginaAtual = new_page;
            this.is_loading = true;
            let params = {
                name: this.filters.name,
                state_id: this.filters.state ? this.filters.state.est_id : null,
                provider_name: this.filters.provider,
                client_id: this.filters.client ? this.filters.client : null,
                page: this.paginaAtual,
                page_size: this.items_by_page_value
            }
            this.svc.load(params)
            .then(response => response.data)
            .then(response => {
                this.sellers = response.data;
                this.total_items = response.meta.total;
                this.is_loading = false;
            }).catch(error => {
                this.is_loading = false;
                ErrorHandlerService.handle(error, this.$store);
            });
        }
    },
    created() {
      this.load();
      this.load_region_data()
      this.load_client_stores()
      this.$store.dispatch('set_breadcrumbs', this.breadcrumbs);
    }
}
</script>

<style lang="scss" scoped>
    @import '@/sass/commons/_tables';

    .seller-icon {
        color: $blue1;
        padding: 0 0.6rem;
        font-size: 1rem;
        &:hover {
            cursor: pointer;
        }
    }

    .id strong {
        font-weight: bolder;
        color: $primary-color;
    }

    td {
        height: 4rem !important;
    }

    .actions {
        justify-content: center;
        i {
            font-size: 1.2rem;
            cursor: pointer;
            &.fa-trash {
                color: red;
            }
        }
    }
.seller-page{
    font-size: 1vw;
    padding-right: 5vw;
    padding-bottom: 5vh;
}
.seller-inputs-container{
    display: flex;
    align-items: flex-end;
    margin-top: 5vh;
    justify-content: space-between;
}
.seller-inputs-container2{
    display: flex;
    align-items: flex-end;
    margin-top: 5vh;
    gap: 5vw;
    justify-content: flex-end;
}

.password-icon {
    cursor: pointer;
    color: #FF7110;
    margin-left: 10px;
}
.page-route-select{
		display: flex;
		align-items: center;
	}
	.page-route-select-text{
  font-weight: 400;
  font-size: 16px;
  line-height: 17px;
  color: #ABABAB;
  margin-right: 20px;
  cursor: pointer;
}
.page-table-header {
  background: #FFFEFC;
  border: 0.5px solid #E5E5E5;
  font-weight: 400;
  color: #605F5F;
  display: flex;
  padding: 1em 0em;
  margin-top: 3vh;
  padding-left: 1vw;
}
.page-route-select-icon{
  color: #FF9954;
  margin-right: 20px;
}
.page-title{
  font-weight: 700;
  font-size: 2.5em;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #605F5F;
  margin-top: 40px;
}
.seller-input-size{
    width: 22%;
}
.id-width{width: 5%;}
.name-width{flex: 1;}
.email-width{width: 12%;}
.login-width{width: 10%;}
.password-width{width: 8%;}
.phone-width{width: 10%;}
.provider-width{width: 10%;}
.state-width{width: 9%;}
.city-width{width: 9%;}
.actions-width{width: 9%;}
.seller-row{
    display: flex;
    align-items: center;
    padding: 3vh 0;
}
.seller-row-text{
    color: #4D4F5C;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-left: 10px;
    white-space: nowrap;
}
.seller-row-icon{
    font-size: 2em;
    cursor: pointer;
}
.page-table-line-special {  
  background: #F7F7F7;
}
.page-table-header-mobile{display: none;}

@media only screen and (max-width: 1000px) {
    .seller-page{ font-size: 1.5vw;}
}
@media only screen and (max-width: 900px) {
    .seller-row{ flex-direction: column; font-size: 2em;}
    .seller-input-size{width: 100%;}
    .id-width{width: 100%;}
    .name-width{flex: unset; width: 100%;}
    .email-width{width: 100%;}
    .login-width{width: 100%;}
    .password-width{width: 100%;}
    .phone-width{width: 100%;}
    .provider-width{width: 100%;}
    .state-width{width: 100%;}
    .city-width{width: 100%;}
    .actions-width{width: 100%;}
    .seller-row-text{display: flex; justify-content: space-between;}
    .page-table-header-mobile{font-size: 1.2em; font-weight: bold; display: flex;}
    .seller-inputs-container{flex-direction: column; gap: 2vh;}
    .seller-input-size-1{width: 30%;}
    .page-table-header{
        display: none;
    }
}
@media only screen and (max-width: 600px) {
    .seller-page{ font-size: 2.5vw;}
}
</style>
